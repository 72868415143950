<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img :src="logo" max-height="200" class="" contain>
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-2" :loading="loading">
              <v-toolbar color="primary" dark flat dense>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Username"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    :error-messages="form_error.email"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :error-messages="form_error.password"
                    @keyup.enter.native="login()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="primary" to="/forgot-password"
                  >Forgot Password</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  v-on:click="login()"
                  :loading="loading"
                  :disabled="loading"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
      logo: "",
    };
  },
  methods: {
    getObjects() {
      let _self = this;
      this.$axios
        .get("logo/get")
        .then(function (response) {
          _self.logo = response.data.data[0].upload_url;
        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    login() {
      let _self = this;
      this.loading = "secondary";
      this.$axios
        .post(`admin/login`, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.data = response_data.user_data;
            localStorage.setItem(
              "pcm_admin",
              JSON.stringify(response_data.user_data)
            );
            this.$axios.defaults.headers.common = {
              Authorization: "Bearer " + response_data.user_data.token,
              Accept: "application/json",
            };
            _self.$router.push("/vendor");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted() {
    this.getObjects();
  },
};
</script>
