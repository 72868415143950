import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
let opts = {
  theme: {
    themes: {
      light: {
        primary: "#114c8f",
        secondary: "#792d8d",
        accent: "#8c9eff",
        error: "#B71C1C",
      },
    },
  },
};
export default new Vuetify(opts);
