import Vue from 'vue'
import Vuex from 'vuex'
import utils from './utils'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    utils,
  }
})

export default store